import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { StaticImage } from "gatsby-plugin-image";
import TwoColumnStatic from "../components/TwoColumnStatic/TwoColumnStatic";
import "../styles/base.scss";
import ColumnStatic from "../components/TwoColumns/Columns/ColumnStatic/ColumnStatic";
import TwoColumns from "../components/TwoColumns/TwoColumns";
import Testimonials from "../components/Testimonials/Testimonials";
import Accordian from "../components/Accordian/Accordian";
import { Icon } from "../components/Icon/Icon";
import PricingPlans from "../components/PricingPlans/PricingPlans";
import PricingFeatures from "../components/PricingFeatures/PricingFeatures";
import Cookies from "js-cookie";
import FullTextBlock from "../components/FullTextBlock/FullTextBlock";
import ThreeColumnStatic from "../components/ThreeColumnStatic/ThreeColumnStatic";

const pricing = ({ data, errors, location }) => {
    if (errors) return <Layout errors={errors} />;

    Cookies.set("pricing_variant_domain", "c0e478ed7b42c69c70160a3e1b968e08", {
        path: "/;domain=7shifts.com",
    });
    Cookies.set("pricing_variant_domain", "c0e478ed7b42c69c70160a3e1b968e08", {
        path: "/;domain=7shiftsdemo.com",
    });
    Cookies.set("pricing_variant", "c0e478ed7b42c69c70160a3e1b968e08");
    Cookies.set("plan_combination", "c0e478ed7b42c69c70160a3e1b968e08");
    Cookies.set("marketing_ab_test", "c0e478ed7b42c69c70160a3e1b968e08");
    const { page } = data;
    const modulesData = page.pageContent.modules;

    const ModuleComponents = modulesData.map((module, key) => {
        switch (module._type) {
            case "testimonials":
                const pricingTestimonials = (
                    <Testimonials testimonialData={module.testimonialList} key={key} />
                );
                return pricingTestimonials;
            case "twoColumns":
                const pricingAddOns = <TwoColumns twoColumnsData={module} key={key} />;
                return pricingAddOns;
            case "accordian":
                const pricingFAQ = <Accordian accordianData={module} key={key} />;
                return pricingFAQ;
            case "fullTextBlock":
                return <FullTextBlock fullTextBlockData={module} key={key} />;
            default:
                return null;
        }
    });

    const href = location.href;
    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = page.pageContent.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href,
    };

    const prices = {
        entreeAnnual: "$29.99",
        entreeMonthly: "$34.99",
        theWorksAnnual: "$69.99",
        theWorksMonthly: "$76.99",
        gourmetAnnual: "$120",
        gourmetMonthly: "$150",
    };

    const gourmetContent = (
        <div className="column-tangerine-bg">
            <div className="column-white-text">
                <h4>Gourmet</h4>
            </div>
            <div>Monitor brand health and automate schedule creation.</div>
            <div className="column-two-column-content">
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">Unlimited employees</span>
                    </div>
                </div>
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">Non-cloud POS integration</span>
                    </div>
                </div>
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">Operations Overview</span>
                    </div>
                </div>
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">
                            Machine-learning Auto-Scheduler
                        </span>
                    </div>
                </div>
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">Webhooks</span>
                    </div>
                </div>
                <div className="column-content-wrapper">
                    <div data-anime-bullet className="column-checkmark">
                        <Icon.CheckmarkWhite />
                        <span className="column-checkmark-item">Task Management</span>
                    </div>
                </div>
            </div>
            <div className="cta-link dark">
                <a aria-label="Free Trial" href="/book-demo">
                    <span>Request Demo</span>
                </a>
            </div>
        </div>
    );

    const leftContent = (
        <ColumnStatic
            headingText={["For enterprise restaurants and chains"]}
            content={gourmetContent}
        ></ColumnStatic>
    );
    const rightContent = (
        <div className="" style={{ position: "relative" }}>
            <div className="static-image-container mt-large">
                <StaticImage
                    src={"../images/brewery-employee.png"}
                    alt="Restaurant server taking an order from customers"
                    auto="format"
                    width={400}
                    height={400}
                    placeholder="blurred"
                />
            </div>
        </div>
    );

    const addOns = (
        <>
            <div className="column-addon-wrapper">
                <div className="column-addon-item">
                    <div>
                        <h5 className="column-addon-title">
                            <Icon.TaskManagement /> Task Management
                        </h5>
                    </div>
                    <div className="column-addon-description">
                        Easily access your daily checklists in a centralized task management system.
                    </div>
                    <div className="column-addon-offer">
                        <div className="column-add-on-price">$12.99</div>
                        <div className="text-grey">Per month/location</div>
                    </div>
                </div>
                <div className="column-addon-item">
                    <div>
                        <h5 className="column-addon-title">
                            <Icon.TipPooling /> Tip Pooling
                        </h5>
                    </div>
                    <div className="column-addon-description">
                        Simplify tip out payments with an easy tool to create, calculate, and track
                        tip pools.
                    </div>
                    <div className="column-addon-offer">
                        <div className="column-add-on-price">$24.99</div>
                        <div className="text-grey">Per month/location</div>
                    </div>
                </div>
            </div>
            <div className="column-addon-wrapper">
                <div className="column-addon-item">
                    <div>
                        <h5 className="column-addon-title">
                            <Icon.OperationsOverview /> Operations Overview
                        </h5>
                    </div>
                    <div className="column-addon-description">
                        Track labor performance & engagement across all your locations in one place.
                    </div>
                    <div className="column-addon-offer">
                        <div className="column-add-on-price">$6.99</div>
                        <div className="text-grey">Per month/location</div>
                    </div>
                </div>
                <div className="column-addon-item">
                    <div>
                        <h5 className="column-addon-title">
                            <Icon.ManagerLogBook /> Manager Log Book
                        </h5>
                    </div>
                    <div className="column-addon-description">
                        Keep managers in sync across shifts with a customizable digital log book.
                    </div>
                    <div className="column-addon-offer">
                        <div className="column-add-on-price">$14.99</div>
                        <div className="text-grey">Per month/location</div>
                    </div>
                </div>
            </div>
        </>
    );

    const leftAddOnContent = (
        <ColumnStatic
            headingText={[
                "Make your restaurant run even smoother with these ",
                <span className="accent">add-ons.</span>,
            ]}
        ></ColumnStatic>
    );
    const rightAddOnContent = <ColumnStatic content={addOns}></ColumnStatic>;

    const contactUs = {
        title: [
            "Want to talk about the plan",
            <span className="accent"> that’s right for your restaurant?</span>,
        ],
        description:
            "Get in touch with our team and we can help you decide which plan will work best for you and your team.",
        one: {
            image: (
                <StaticImage
                    src={"../images/icons/phone-icon.png"}
                    alt="Icon of a phone"
                    auto="format"
                    placeholder="blurred"
                    className="primary-img"
                />
            ),
            backdropImage: (
                <StaticImage
                    src={"../images/backdrops/garlic-flourish.png"}
                    alt="Icon of a phone"
                    auto="format"
                    placeholder="blurred"
                    className="backdrop-img"
                />
            ),
            title: "Give us a call",
            text: "1-888-979-5877",
            link: "tel:1-888-979-5877",
        },
        two: {
            image: (
                <StaticImage
                    src={"../images/icons/email-icon.png"}
                    alt="Icon of an envelope"
                    auto="format"
                    placeholder="blurred"
                    className="primary-img"
                />
            ),
            backdropImage: (
                <StaticImage
                    src={"../images/backdrops/line-flourish.png"}
                    alt="Icon of a phone"
                    auto="format"
                    placeholder="blurred"
                    className="backdrop-img"
                />
            ),
            title: "Contact us",
            text: "sales@7shifts.com",
            link: "mailto:sales@7shifts.com",
        },
        three: {
            image: (
                <StaticImage
                    src={"../images/icons/chat-icon.png"}
                    alt="Icon of a chat bubble"
                    auto="format"
                    placeholder="blurred"
                    className="primary-img"
                />
            ),
            backdropImage: (
                <StaticImage
                    src={"../images/backdrops/dots-flourish.png"}
                    alt="Icon of a phone"
                    auto="format"
                    placeholder="blurred"
                    className="backdrop-img"
                />
            ),
            title: "Chat with us",
            text: "Start a live chat",
            link: "#",
            // intercom: Intercom(
            //     "showNewMessage",
            //     "Hi! I want to talk about the plan that’s right for my restaurant"
            // ),
        },
    };

    return (
        <Layout
            seoData={seoPageData}
            heroContent={page.pageContent.hero}
            pageHeading={page.pageContent.pageHeading}
            hideForm
        >
            <PricingPlans plansData={prices} />
            {ModuleComponents[0]}
            <TwoColumnStatic
                wrapperClasses=""
                LeftContentClasses=""
                rightContentClasses=""
                leftContent={leftContent}
                rightContent={rightContent}
            />
            <PricingFeatures plansData={prices} />
            <TwoColumnStatic
                wrapperClasses=""
                LeftContentClasses=""
                rightContentClasses=""
                leftContent={leftAddOnContent}
                rightContent={rightAddOnContent}
            />
            <ThreeColumnStatic threeColumnsData={contactUs} />
            {ModuleComponents[1]}
            {ModuleComponents[2]}
        </Layout>
    );
};

export default pricing;

export const query = graphql`
    query pricingQuery {
        page: sanityPage(pageTitle: { eq: "Pricing" }) {
            title: pageTitle
            slug {
                current
            }
            id
            pageContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                }
                hero: heroBanner {
                    eyebrow
                    title: heroTitle {
                        style
                        _key
                        _type
                        children {
                            _type
                            _key
                            marks
                            text
                        }
                    }
                    marqueeText
                    marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            filename
                            url
                        }
                    }
                    primaryImage {
                        ...ImageWithPreview
                    }
                    primaryalt
                    secondaryImage {
                        ...ImageWithPreview
                    }
                    secondaryalt
                    backdropImage {
                        ...ImageWithPreview
                    }
                    ctaButtonOne {
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        title
                    }
                    ctaButtonTwo {
                        title
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                    subtext
                }
                modules {
                    ... on SanityTestimonials {
                        _key
                        _type
                        testimonialList {
                            jobTitle
                            name
                            quote
                            logoAlt
                            image {
                                ...ImageWithPreview
                            }
                            logo {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityStats {
                        backdropImage {
                            ...ImageWithPreview
                        }
                        _key
                        _type
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityAccordian {
                        _key
                        _type
                        title: accordianTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        list {
                            title
                            description {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    text
                                }
                            }
                        }
                    }
                    ... on SanitySocialProofMarquee {
                        _key
                        _type
                        title: headingText {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        useCustomLogos
                        useCustomHeading
                        socialProofImages {
                            ...ImageWithPreview
                            asset {
                                metadata {
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                url
                            }
                        }
                    }
                    ... on SanityFullTextBlock {
                        _key
                        _type
                        heading {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        body {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        internalLink
                        ctaText
                        ctaLink
                    }
                    ... on SanityTwoColumns {
                        _key
                        _type
                        swappable {
                            _type
                            _key
                            title: titleWithAccent {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            columnOne {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityThreeColumns {
                        _key
                        _type
                        threeColumnOne {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnTwo {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnThree {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                    }
                }
                pageHeading {
                    style
                    _key
                    _type
                    children {
                        _type
                        _key
                        marks
                        text
                    }
                }
            }
        }
    }
`;
